import "./App.scss";

import Form from "./components/Form";
import Flags from "./components/Flags";

function App() {
  return (
    <div className="App">
      <Flags></Flags>
      <div className="background"></div>
      <div className="content">
        <Form></Form>
      </div>
    </div>
  );
}

export default App;
