import axios from "axios";

export const isEmail = RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);

export const validateCoupon = function ({ email, company, couponId }) {
  return axios.post("https://coupon.mindbank.ai/api/v1/coupon/validate", {
    email,
    // company,
    couponId,
  });
};

export const redeemCoupon = function ({ email, company, couponId, industry }) {
  return axios.post("https://coupon.mindbank.ai/api/v1/coupon/redeem", {
    email,
    company,
    couponId,
    industry,
  });
};
