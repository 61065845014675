export const sectors = [
  "Transport and Infrastructures",
  "Digital technologies and services",
  "Agriculture; plantations;other rural sectors",
  "Basic Metal Production",
  "Chemical industries",
  "Commerce",
  "Construction",
  "Education",
  "Financial services; professional services",
  "Engineering",
  "IT",
  "Automotive",
  "Logistics",
  "Transportation can",
  "Retail"
];

export const countries = [
  {
    flag: "GB",
    lang: "en",
  },
  {
    flag: "DE",
    lang: "de",
  },
  {
    flag: "CZ",
    lang: "cz",
  },
  {
    flag: "SK",
    lang: "sk",
  },
];
