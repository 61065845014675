import React from "react";

import "./style.scss";

import { countries } from "../../utils/data";

import { useTranslation } from "react-i18next";

function Flags() {
  const { t, i18n } = useTranslation();

  return (
    <div className="countries">
      {countries.map(({ flag, lang }) => {
        return (
          <img
            onClick={(e) => {
              i18n.changeLanguage(lang);
              localStorage.setItem("lang", lang);
              window.location.reload(false);
            }}
            src={`https://www.countryflagicons.com/FLAT/64/${flag}.png`}
          ></img>
        );
      })}
    </div>
  );
}

export default Flags;
