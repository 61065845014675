import React, { useState, useRef, useCallback } from "react";
import debounce from "lodash.debounce";
import { useTranslation } from "react-i18next";

import { sectors } from "../../utils/data";
import { isEmail, validateCoupon, redeemCoupon } from "../../utils";

import "./style.scss";

function Form() {
  const emailRef = useRef(null);
  const companyRef = useRef(null);
  const couponRef = useRef(null);
  const dropdownRef = useRef(null);
  const [validationMessages, setValidationMessages] = useState({
    email: {
      correct: null,
      error: null,
    },
    coupon: {
      correct: null,
      error: null,
    },
    redeemed: {
      correct: null,
      error: null,
    },
  });

  const { t, i18n } = useTranslation();

  const handleChange = (e) => {
    let result = { ...validationMessages };
    const email = emailRef.current.value;

    // validate email
    if (email === "") {
      result = {
        ...validationMessages,
        email: {
          correct: null,
          error: t("email.required") + " ✘",
        },
      };
    } else if (!isEmail.test(email)) {
      result = {
        ...validationMessages,
        email: {
          correct: null,
          error: t("email.invalid") + " ✘",
        },
      };
    } else {
      result = {
        ...validationMessages,
        email: {
          correct: t("email.valid") + " ✓",
          error: null,
        },
      };
    }
    validateCoupon({
      email: emailRef.current.value,
      // company: companyRef.current.value,
      couponId: couponRef.current.value,
    })
      .then((res) => {
        console.log("res........");
        if (res.data.isValid === true) {
          setValidationMessages({
            ...result,
            coupon: {
              correct: t("coupon.valid") + " ✓",
              error: null,
            },
          });
        }
      })
      .catch(({ response: { status } }) => {
        if (status === 404) {
          setValidationMessages({
            ...result,
            coupon: {
              correct: null,
              error: t("coupon.notFound") + " ✘",
            },
          });
        } else if (status === 400) {
          setValidationMessages({
            ...result,
            coupon: {
              correct: null,
              error: t("coupon.alreadyRedeemed") + " ✘",
            },
          });
        }
      });
  };

  const debouncedChangeHandler = useCallback(debounce(handleChange, 200), []);

  const handleSubmit = (e) => {
    e.preventDefault();

    // validation
    const values = Object.values(validationMessages);
    for (const v of values) {
      if (v.error) {
        setValidationMessages({
          ...validationMessages,
          redeemed: {
            correct: null,
            error: t("redeem.fail"),
          },
        });
        return;
      }
    }

    redeemCoupon({
      email: e.target.elements.email.value,
      company: e.target.elements.company.value,
      couponId: e.target.elements.coupon.value,
      industry: e.target.elements.sector.value,
    })
      .then((res) => {
        console.log(res);
        if (res.status === 201) {
          setValidationMessages({
            ...validationMessages,
            redeemed: {
              correct: t("redeem.success"),
              error: null,
            },
          });
        } else {
          setValidationMessages({
            ...validationMessages,
            redeemed: {
              correct: null,
              error: t("redeem.fail"),
            },
          });
        }
      })
      .catch((err) => {
        setValidationMessages({
          ...validationMessages,
          redeemed: {
            correct: null,
            error: t("redeem.fail"),
          },
        });
      });
  };

  return (
    <div className="form-wrapper" noValidate onSubmit={handleSubmit}>
      <img src="images/Rectangle_132.svg"></img>
      {validationMessages.redeemed.correct ? (
        <h1>{validationMessages.redeemed.correct}</h1>
      ) : (
        <>
          <h1>
            {t("header.part1")}
            {<br />} {t("header.part2")}
          </h1>
          <form>
            <div className="input-group">
              <input
                ref={emailRef}
                onChange={debouncedChangeHandler}
                name="email"
                placeholder="Email*"
                type="email"
              ></input>
              {validationMessages.email.correct && (
                <p>{validationMessages.email.correct}</p>
              )}
              {validationMessages.email.error && (
                <p>{validationMessages.email.error}</p>
              )}
            </div>
            <div className="input-group">
              <input
                ref={couponRef}
                onChange={debouncedChangeHandler}
                className="coupon"
                name="coupon"
                placeholder="Coupon*"
                type="text"
              ></input>
              {validationMessages.coupon.correct && (
                <p>{validationMessages.coupon.correct}</p>
              )}
              {validationMessages.coupon.error && (
                <p>{validationMessages.coupon.error}</p>
              )}
            </div>
            <input
              ref={companyRef}
              onChange={debouncedChangeHandler}
              name="company"
              placeholder={t("placeholder.company")}
              type="text"
            ></input>
            <select
              onChange={() => (dropdownRef.current.style.color = "#000000")}
              style={{ color: "#c1c1c1" }}
              name="sector"
              ref={dropdownRef}
            >
              <option value="" selected disabled hidden>
                {t("placeholder.sector")}
              </option>
              {sectors.map((sector, key) => (
                <option value={sector} key={key}>
                  {t(`dropdown.${sector}`)}
                </option>
              ))}
            </select>
            <div className="input-group submit">
              {validationMessages.redeemed.error && (
                <p>{validationMessages.redeemed.error}</p>
              )}
              <input type="submit" value={t("submitBtnText")}></input>
            </div>
          </form>
        </>
      )}
    </div>
  );
}

export default Form;
