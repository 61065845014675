// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../public/images/phones.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../public/images/MINDBANK·AI WHITE.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../public/images/background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@charset \"UTF-8\";\n.App {\n  display: flex;\n  min-height: 100vh;\n  background-color: #f1f1f1;\n}\n.App .background {\n  flex: 1 1;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "), url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + "), url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n  background-position: top 313px left 50%, top 99px left 50%, center;\n  background-repeat: no-repeat;\n  background-size: 960px, 345px, cover;\n}\n.App .content {\n  flex: 1 1;\n}", "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,aAAA;EACA,iBAAA;EACA,yBAAA;AAEF;AAAE;EACE,SAAA;EAEA,2IAAA;EAGA,kEAAA;EACA,4BAAA;EACA,oCAAA;AADJ;AAIE;EACE,SAAA;AAFJ","sourcesContent":[".App {\n  display: flex;\n  min-height: 100vh;\n  background-color: #f1f1f1;\n\n  .background {\n    flex: 1;\n    // background-color: orange;\n    background-image: url(\"../public/images/phones.png\"),\n      url(\"../public/images/MINDBANK·AI\\ WHITE.png\"),\n      url(\"../public/images/background.png\");\n    background-position: top 313px left 50%, top 99px left 50%, center;\n    background-repeat: no-repeat;\n    background-size: 960px, 345px, cover;\n  }\n\n  .content {\n    flex: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
